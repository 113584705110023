import React from "react";
import MusicPlayer from "../components/MusicPlayer";

function Music() {
  return (
    <div className="music-page">
      <h1>Музыка</h1>
      <MusicPlayer />
    </div>
  );
}

export default Music;

import React, { useState, useRef, useEffect } from "react";
import "./MusicPlayer.css";

const tracks = [
  { name: "Дом", url: "/tracks/Дом.mp3" },
  { name: "В очереди", url: "/tracks/В очереди.mp3" },
  { name: "Техника молодежи", url: "/tracks/Техника молодежи.mp3" },
];

const Music = () => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const audioRef = useRef(null);
  const progressRefs = useRef([]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const handlePlay = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handleStop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const handleNext = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    setIsPlaying(true);
  };

  const handlePrev = () => {
    setCurrentTrackIndex(
      (prevIndex) => (prevIndex - 1 + tracks.length) % tracks.length
    );
    setIsPlaying(true);
  };

  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
  };

  const handleTimeUpdate = () => {
    if (progressRefs.current[currentTrackIndex]) {
      progressRefs.current[currentTrackIndex].value =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
    }
  };

  const handleProgressChange = (e, index) => {
    if (index === currentTrackIndex) {
      audioRef.current.currentTime =
        (e.target.value / 100) * audioRef.current.duration;
    }
  };

  const handleTrackLoad = () => {
    if (isPlaying) {
      audioRef.current.play();
    }
  };

  const handleTrackClick = (index) => {
    setCurrentTrackIndex(index);
    setIsPlaying(true);
  };

  return (
    <div className="music-section">
      {/* <h2>Музыка группы Люто</h2> */}
      <div className="track-list">
        {tracks.map((track, index) => (
          <div
            key={index}
            className={`track-item ${
              currentTrackIndex === index ? "active" : ""
            }`}
            onClick={() => handleTrackClick(index)}
          >
            <div className="track-info">
              <h3>{track.name}</h3>
              <input
                ref={(el) => (progressRefs.current[index] = el)}
                type="range"
                min="0"
                max="100"
                defaultValue="0"
                onChange={(e) => handleProgressChange(e, index)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="controls">
        <input
          type="range"
          className="volume-slider"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          title="Громкость"
        />
        <button
          onClick={handlePlay}
          className={`play-btn ${isPlaying ? "active" : ""}`}
        >
          ▶️
        </button>
        <button
          onClick={handlePause}
          className={`pause-btn ${
            !isPlaying && audioRef.current && !audioRef.current.paused
              ? "active"
              : ""
          }`}
        >
          ⏸️
        </button>
        <button
          onClick={handleStop}
          className={`stop-btn ${
            !isPlaying && audioRef.current && audioRef.current.currentTime === 0
              ? "active"
              : ""
          }`}
        >
          ⏹️
        </button>
        <div className="side-by-side">
          <button onClick={handlePrev} className="prev-btn">
            ⏮️
          </button>
          <button onClick={handleNext} className="next-btn">
            ⏭️
          </button>
        </div>
      </div>
      <audio
        ref={audioRef}
        src={tracks[currentTrackIndex].url}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={handleTrackLoad}
        onEnded={handleNext}
      />
    </div>
  );
};

export default Music;

import React, { useState } from "react";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Music from "./pages/Music";
import Video from "./pages/Video";
import Contacts from "./pages/Contacts";
import "./App.css";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="App">
        <header>
          <div className="menu-toggle" onClick={toggleMenu}>
            ☰
          </div>
          <nav className={menuOpen ? "open" : ""}>
            <Link to="/" onClick={() => setMenuOpen(false)}>
              Главная
            </Link>
            <Link to="/about" onClick={() => setMenuOpen(false)}>
              О группе
            </Link>
            <Link to="/music" onClick={() => setMenuOpen(false)}>
              Музыка
            </Link>
            <Link to="/video" onClick={() => setMenuOpen(false)}>
              Видеоклипы
            </Link>
            <Link to="/contacts" onClick={() => setMenuOpen(false)}>
              Контакты
            </Link>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/music" element={<Music />} />
          <Route path="/video" element={<Video />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
